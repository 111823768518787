<template>
  <slot v-if="show" name="default" />
  <slot v-else name="placeholder" />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'ClientOnly',
  setup() {
    const show = ref<boolean>(false)
    onMounted(() => {
      show.value = true
    })
    return { show }
  }
})
</script>
